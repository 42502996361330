import HeaderContainer_BevelScreenComponent from '@wix/thunderbolt-elements/src/components/HeaderContainer/viewer/skinComps/BevelScreen/BevelScreen.skin';


const HeaderContainer_BevelScreen = {
  component: HeaderContainer_BevelScreenComponent
};


export const components = {
  ['HeaderContainer_BevelScreen']: HeaderContainer_BevelScreen
};


// temporary export
export const version = "1.0.0"
